import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { useLocation } from 'wouter';
import { v4 as uuidv4 } from 'uuid';

const initialStyles = {
  businessName: {},
  dba: {},
  referrer: {},
  referrerEmail: {},
  useOfFunds: {},
  address: {},
  city: {},
  state: {},
  zip: {},
  tel: {},
  dateIncorporated: {},
  industry: {},
  businessDescription: {},
  ownerName: {},
  ownerTel: {},
  ownerEmail: {},
  ownerDOB: {},
  ownerSSN: {},
  pctOwnership: {},
  driversLicenseFront: {},
  bankStatement1: {},
  bankStatement2: {},
  bankStatement3: {},
  businessApplication: {},
  taxReturn1: {},
  taxReturn2: {},
  personalTaxReturn1: {},
};

const CreateDealPage = ({ user, userInfo }) => {
  const [formData, setFormData] = useState({
    // referrer: 'John Doe',
    // referrerEmail: 'test@example.com',
    // useOfFunds: 'Working capital and expansion',
    // businessName: 'Doe Enterprises LLC',
    // dba: 'Doe Trading',
    // address: '123 Main St',
    // city: 'New York',
    // state: 'NY',
    // zip: '10001',
    // tel: '5551234567',
    // dateIncorporated: '2010-05-15',
    // industry: 'Retail',
    // businessDescription: 'A retail company specializing in consumer goods.',
    // ownerName: 'John Doe',
    // ownerTel: '5559876543',
    // ownerEmail: 'owner@example.com',
    // ownerDOB: '1980-07-10',
    // ownerSSN: '123456789',
    // pctOwnership: '100',

    referrer: '',
    referrerEmail: '',
    useOfFunds: '',
    businessName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    tel: '',
    dateIncorporated: '',
    industry: '',
    businessDescription: '',
    ownerName: '',
    ownerTel: '',
    ownerEmail: '',
    ownerDOB: '',
    ownerSSN: '',
    pctOwnership: '',

    hasLargeLenderBalances: 'no',
    driversLicenseFront: '',
    bankStatement1: '',
    bankStatement2: '',
    bankStatement3: '',
    bankStatement4: '',
    businessApplication: '',
    plBusinessStatement: '',
    microSbaApplication: '',
    quidityLibriumSBA: '',
    taxReturn1: '',
    taxReturn2: '',
    personalTaxReturn1: '',
  }
)

const invalidStyle = { outline: '2px solid red' }; // Red outline for invalid fields
const validStyle = { outline: '2px solid #078586' }; // Green outline for valid fields


  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitStatusMessage, setSubmitStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
// eslint-disable-next-line
  const [location, navigate] = useLocation();
  const [fieldStyles, setFieldStyles] = useState(initialStyles);

  const [hasChanged, setHasChanged] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  // Validate formData and checkbox when they change
  useEffect(() => {
    setIsFormValid(areAllFieldsFilled());
  }, [formData, isCheckboxChecked]);

  useEffect(() => {
    if (hasChanged) {
      // Trigger validation for all fields after the first change
      Object.keys(formData).forEach((key) => {
        validateField(key, formData[key]);
      });
    }
  }, [formData, hasChanged]);
  

  const industryCategories = {
    'Agriculture': ['None of the below', 'Non-profit'],
    'Automotive Repair': [
      'Body repair, collision, painting',
      'General repair, truck, rv',
      'Parts, accessories',
      'Quick service lube, tune-up',
      'Tire, glass',
      'Transmission',
      'Car wash',
      'Upholstery',
      'Non-profit'
    ],
    'Automobile Sales & Gas Services Stations': [
      'None of the below',
      'Automotive dealerships new/used',
      'Gas station/Convenience',
      'New & Used car dealers',
      'RV dealers',
      'Non-profit'
    ],
    'Business Services': [
      'None of the below',
      'Advertising sales',
      'Appraisal services',
      'Billing services',
      'Direct/Multi-level marketing',
      'Mail center',
      'Printing/graphic design',
      'Staffing/temp agencies',
      'Non-profit'
    ],
    'Communications': ['None of the below', 'Non-profit'],
    'Construction & Contractors': [
      'None of the below',
      'Construction contractors',
      'Electricians',
      'Flooring',
      'Heating/Air conditioning/HVAC',
      'House remodeling/improvements (ir. Structural remodeling)',
      'Housing related',
      'Painters',
      'Plumbers',
      'Roofing/siding',
      'Tile/carpet',
      'Windows',
      'Non-profit'
    ],
    'Eating & Drinking Places': [
      'None of the below',
      'Bar',
      'Catering',
      'Ice cream shops',
      'Restaurant - casual dining',
      'Restaurant - fast food & carry out',
      'Restaurant - fine dining',
      'Non-profit'
    ],
    'Educational Services': [
      'None of the below',
      'Daycare',
      'Schools',
      'Schools - educational training certifications',
      'Schools - private/elementary/high school',
      'Seminars',
      'Non-profit'
    ],
    'Entertainment Services': [
      'None of the below',
      'Adult entertainment',
      'Art galleries',
      'Casinos',
      'Cruise ships',
      'Entertainment venue',
      'Event planning',
      'Ticket brokers',
      'Travel agencies',
      'Non-profit'
    ],
    'Finance': [
      'None of the below',
      'Accounting',
      'Bail bonds',
      'Check cashing companies',
      'Collection agencies',
      'Consumer credit counseling services',
      'Payday loan companies',
      'Tax return preparation services',
      'Non-profit'
    ],
    'Food & Beverage Stores': [
      'None of the below',
      'Food- bakery',
      'Food - convenience & variety',
      'Food - deli, meat, seafood, other',
      'Food - grocery store',
      'Liquor store',
      'Non-profit'
    ],
    'Freight': ['None of the below', 'Tow truck and yard', 'Tractor trailer transportation', 'Non-profit'],
    'Health Services': [
      'None of the below',
      'Dental',
      'Hair replacements/clubs',
      'Health club',
      'Health club monthly membership',
      'Marijuana shop',
      'Medical services',
      'Pharmacy',
      'Spa Weightless centers',
      'Non-profit'
    ],
    'Hotels & Lodging': ['None of the below', 'Hotel & motel', 'Timeshares', 'Non-profit'],
    'Insurance': ['None of the below', 'Insurance', 'Warranty companies', 'Non-profit'],
    'Legal Services': ['None of the below', 'Law firms', 'Professional service (All doctors, lawyers)', 'Non-profit'],
    'Manufacturing': ['None of the below', 'Non-profit'],
    'Mining & Natural Resource Extraction': ['None of the below', 'Non-profit'],
    'Miscellaneous Services': [
      'None of the below',
      'Equipment rental',
      'Guard dog service',
      'Internet prescription',
      'Other service retail',
      'Non-profit'
    ],
    'Personal Services': [
      'None of the below',
      'Annual memberships',
      'Dry cleaners',
      'Hair & nail salon',
      'Interior design',
      'Lawn & garden',
      'Maid & cleaning services',
      'Online dating/memberships',
      'Pet washing & grooming',
      'Tanning & tattoo',
      'Non-profit'
    ],
    'Real Estate': ['None of the below', 'Property management/real estate'],
    'Religious Services': ['None of the below', 'Churches', 'Funeral homes', 'Non-profit'],
    'Retail': [
      'None of the below',
      'Antiques/collectibles',
      'Auctions houses',
      'Bridal shop',
      'Clothing & apparel',
      'Computer-related retail',
      'Ebay sales',
      'Electronics',
      'Firearms',
      'Flea market',
      'Florists',
      'Furniture',
      'Furriers',
      'Golf- stores, driving ranges, country clubs',
      'Heating oil',
      'Home appliance stores',
      'Home based door/door',
      'Home entertainment systems',
      'Internet sales (no storefront)',
      'Internet sales (storefront)',
      'Internet software downloads',
      'Jewelry',
      'Kiosks',
      'Mattresses',
      'Pet store',
      'Pools/spas',
      'Sporting goods',
      'Tobacco shop',
      'Wireless phone/accessories',
      'Other product retail',
      'Non-profit'
    ],
    'Storage': ['None of the below', 'Boating/marina', 'Non-profit'],
    'Transportation': [
      'None of the below',
      'Airlines',
      'Daycare services - home based not allowed',
      'Day tours',
      'Limousine & transportation',
      'Non-profit'
    ],
    'Wholesale': ['None of the below', 'Import/export', 'Non-profit'],
    'Vehicle Rental': ['None of the below', 'Non-profit']
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (!hasChanged) {
      setHasChanged(true);
    }
  
    if (files) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  
    validateField(name, value || files?.[0]?.name); // Run validation on change
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setLoading(true);
      setSubmitStatusMessage('Submitting records...');
      
      const files = [
        { name: 'driversLicenseFront', content: await fileToBase64(formData.driversLicenseFront), type: formData.driversLicenseFront?.type },
        { name: 'bankStatement1', content: await fileToBase64(formData.bankStatement1), type: formData.bankStatement1?.type },
        { name: 'bankStatement2', content: await fileToBase64(formData.bankStatement2), type: formData.bankStatement2?.type },
        { name: 'bankStatement3', content: await fileToBase64(formData.bankStatement3), type: formData.bankStatement3?.type },
        { name: 'businessApplication', content: await fileToBase64(formData.businessApplication), type: formData.businessApplication?.type },
        formData.bankStatement4 ? { name: 'bankStatement4', content: await fileToBase64(formData.bankStatement4), type: formData.bankStatement4?.type } : null,  // Add bankStatement4
        formData.taxReturn1 ? { name: 'taxReturn1', content: await fileToBase64(formData.taxReturn1), type: formData.taxReturn1?.type } : null,
        formData.taxReturn2 ? { name: 'taxReturn2', content: await fileToBase64(formData.taxReturn2), type: formData.taxReturn2?.type } : null,
        formData.personalTaxReturn1 ? { name: 'personalTaxReturn1', content: await fileToBase64(formData.personalTaxReturn1), type: formData.personalTaxReturn1?.type } : null,
        formData.microSbaApplication ? { name: 'microSbaApplication', content: await fileToBase64(formData.microSbaApplication), type: formData.microSbaApplication?.type } : null,
        formData.quidityLibriumSBA ? { name: 'quidityLibriumSBA', content: await fileToBase64(formData.quidityLibriumSBA), type: formData.quidityLibriumSBA?.type } : null,
        formData.plBusinessStatement ? { name: 'plBusinessStatement', content: await fileToBase64(formData.plBusinessStatement), type: formData.plBusinessStatement?.type } : null,
      ].filter(Boolean); // Filters out any null values
      
  
      const docId = uuidv4();
  
      // Upload data to Firestore
      await setDoc(doc(db, 'deals', docId), {
        ...formData,
        userId: user.email,
        userEmail: user.email,
        dateSubmitted: serverTimestamp(),
        status: 'Processing',
        driversLicenseFront: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        bankStatement4: '',
        businessApplication: '',
        taxReturn1: '',
        taxReturn2: '',
        personalTaxReturn1: '',
        microSbaApplication: '',
        quidityLibriumSBA: '',
        plBusinessStatement: '',
      });

      const formDataObject = {
        ...formData,
        driversLicenseFront: files[0],
        bankStatement1: files[1],
        bankStatement2: files[2],
        bankStatement3: files[3],
        businessApplication: files[4],
        bankStatement4: files[5] || null,
        taxReturn1: files[6] || null,
        taxReturn2: files[7] || null,
        personalTaxReturn1: files[8] || null,
        microSbaApplication: files[9] || null,
        quidityLibriumSBA: files[10] || null,
        plBusinessStatement: files[11] || null,
        docId,
      };
      
      // console.log('files', files);
      // console.log('files to send', formDataObject);

      // Send data to external API
      // const response = await axios.post('http://127.0.0.1:5001/quidity-app/us-central1/createDeal', {
      const response = await axios.post('https://us-central1-quidity-app.cloudfunctions.net/createDeal', {
        formData: formDataObject,
        userId: user.uid,
        userEmail: user.email,
        userInfo,
        docId,
      });

      setSubmitStatusMessage(response.data.message);
      setLoading(false);
      navigate('/portal');
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !(file instanceof Blob)) {
        // If the file is not provided or not a Blob, return 'N/A'
        resolve('N/A');
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateField = (name, value) => {
    let isValid = false;
  
    // Handle undefined values or file inputs
    if (typeof value === 'string') {
      value = value.trim();  // Only trim if it's a string
    }
  
    switch (name) {
      case 'referrerEmail':
      case 'ownerEmail':
        isValid = validateEmail(value);
        break;
      case 'zip':
        isValid = value?.length === 5;
        break;
      case 'tel':
      case 'ownerTel':
        isValid = value?.length === 10;
        break;
      case 'ownerSSN':
        isValid = value?.length === 9 && parseInt(value);
        break;
      case 'businessName':
      case 'referrer':
      case 'useOfFunds':
      case 'address':
      case 'city':
      case 'state':
      case 'dateIncorporated':
      case 'industry':
      case 'businessDescription':
      case 'ownerName':
      case 'ownerDOB':
      case 'pctOwnership':
        isValid = typeof value === 'string' && value?.trim() !== '';
        break;
      case 'driversLicenseFront':
      case 'bankStatement1':
      case 'bankStatement2':
      case 'bankStatement3':
      case 'businessApplication':
        isValid = !!value;  // Ensure the file or string is present
        break;
      default:
        isValid = true;
    }
  
    // Apply valid or invalid style
    setFieldStyles((prevStyles) => ({
      ...prevStyles,
      [name]: isValid ? validStyle : invalidStyle,
    }));
  };
  
  
  

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    let updatedStyles = { ...initialStyles };
  
    if (name === 'referrerEmail' || name === 'ownerEmail') {
      updatedStyles[name] = validateEmail(formData?.[name]) ? validStyle : invalidStyle;
    }
  
    setFieldStyles(prevStyles => ({
      ...prevStyles,
    }));
  };
  

  const isFieldValid = (field, type = 'text') => {
    if (type === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(field);
    } else if (type === 'tel') {
      return field.length === 10;
    } else if (type === 'zip') {
      return field.length === 5;
    } else if (type === 'ssn') {
      return field.length === 9;
    } else if (typeof field === 'string') {
      return field.trim() !== '';
    } else if (field instanceof File) {
      return field.name !== '';  // Checking if the file has a name
    } else {
      return field !== null && field !== undefined;
    }
  };
  
  const areAllFieldsFilled = () => {
    const {
      referrer,
      referrerEmail,
      useOfFunds,
      businessName,
      address,
      city,
      state,
      zip,
      tel,
      dateIncorporated,
      industry,
      businessDescription,
      ownerName,
      ownerTel,
      ownerEmail,
      ownerDOB,
      ownerSSN,
      pctOwnership,
      driversLicenseFront,
      bankStatement1,
      bankStatement2,
      bankStatement3,
      businessApplication,
    } = formData;
  
    // Define the required fields and their validity
    return [
      isFieldValid(referrer),
      isFieldValid(referrerEmail, 'email'),
      isFieldValid(useOfFunds),
      isFieldValid(businessName),
      isFieldValid(address),
      isFieldValid(city),
      isFieldValid(state),
      isFieldValid(zip, 'zip'),
      isFieldValid(tel, 'tel'),
      isFieldValid(dateIncorporated),
      isFieldValid(industry),
      isFieldValid(businessDescription),
      isFieldValid(ownerName),
      isFieldValid(ownerTel, 'tel'),
      isFieldValid(ownerEmail, 'email'),
      isFieldValid(ownerDOB),
      isFieldValid(ownerSSN, 'ssn'),
      isFieldValid(pctOwnership),
      isFieldValid(driversLicenseFront),
      isFieldValid(bankStatement1),
      isFieldValid(bankStatement2),
      isFieldValid(bankStatement3),
      isFieldValid(businessApplication),
      isCheckboxChecked,
    ].every(Boolean); // Returns true if all fields are valid
  };

  return (
    user &&
    <div className="bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center" style={{ animation: 'bottom-top 500ms ease' }}>
      <div className="mt-[200px] md:mt-[150px] lg:mt-[150px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 w-[95%] md:w-[800px] lg:w-[800px] mb-[50px]">
        <h1 className="block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2">Create Deal</h1>
        <form onSubmit={handleSubmit}>
          <p className='text-lg font-medium mb-2 text-center'>Who should we contact regarding this merchant?</p>
          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">Referrer *</label>
              <input
                style={fieldStyles.referrer}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                id="referrer"
                name="referrer"
                value={formData.referrer}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="referrerEmail">Referrer Email *</label>
              <input
                style={fieldStyles.referrerEmail}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                id="referrerEmail"
                name="referrerEmail"
                value={formData.referrerEmail}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessName">Business Name *</label>
              <input
                style={fieldStyles.businessName}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                id="businessName"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">Company Address *</label>
            <input
              style={fieldStyles.address}
              onBlur={handleBlur}
              onFocus={handleFocus}
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

          <div className="grid grid-cols-3 gap-0 md:gap-4 lg:gap-4 mb-4">
            <input
              style={fieldStyles.city}
              onBlur={handleBlur}
              onFocus={handleFocus}
              type="text"
              placeholder="City *"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input"
              required
            />
            <select
              style={fieldStyles.state}
              onBlur={handleBlur}
              onFocus={handleFocus}
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input cursor-pointer"
              required
            >
              <option value="">State *</option>
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NE">NE</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
            </select>
            <input
              style={fieldStyles.zip}
              onBlur={handleBlur}
              onFocus={handleFocus}
              maxLength={5}
              type="text"
              placeholder="Zip Code *"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input"
              required
            />
          </div>

          <div className='flex items-center flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="tel">Telephone *</label>
              <input
                style={fieldStyles.tel}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                maxLength={10}
                id="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dateIncorporated">Date Incorporated *</label>
              <input
                style={fieldStyles.dateIncorporated}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="date"
                id="dateIncorporated"
                name="dateIncorporated"
                value={formData.dateIncorporated}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessDescription">Business Description *</label>
            <textarea
              maxLength={250}
              style={fieldStyles.businessDescription}
              onBlur={handleBlur}
              onFocus={handleFocus}
              id="businessDescription"
              name="businessDescription"
              value={formData.businessDescription}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="useOfFunds">Use of Funds *</label>
            <input
              style={fieldStyles.useOfFunds}
              onBlur={handleBlur}
              onFocus={handleFocus}
              type="text"
              id="useOfFunds"
              name="useOfFunds"
              value={formData.useOfFunds}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="industry">Industry *</label>
              <select
                style={fieldStyles.industry}
                onBlur={handleBlur}
                onFocus={handleFocus}
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                className="themed-input cursor-pointer"
                required
              >
                <option value="">Select Industry</option>
                {Object.keys(industryCategories).map((ind) => (
                  <option key={ind} value={ind}>{ind}</option>
                ))}
              </select>
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerTel">Business Owner Telephone *</label>
              <input
                style={fieldStyles.ownerTel}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                maxLength={10}
                id="ownerTel"
                name="ownerTel"
                value={formData.ownerTel}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerName">Business Owner Name *</label>
              <input
                style={fieldStyles.ownerName}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                id="ownerName"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
            
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerEmail">Business Owner Email *</label>
              <input
                style={fieldStyles.ownerEmail}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="email"
                id="ownerEmail"
                name="ownerEmail"
                value={formData.ownerEmail}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerDOB">Business Owner DOB *</label>
              <input
                style={fieldStyles.ownerDOB}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="date"
                id="ownerDOB"
                name="ownerDOB"
                value={formData.ownerDOB}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
        
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerSSN">Business Owner SSN *</label>
              <input
                style={fieldStyles.ownerSSN}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="text"
                maxLength={9}
                id="ownerSSN"
                name="ownerSSN"
                value={formData.ownerSSN}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
        
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="pctOwnership">Percentage Ownership *</label>
              <select
                style={fieldStyles.pctOwnership}
                onBlur={handleBlur}
                onFocus={handleFocus}
                id="pctOwnership"
                name="pctOwnership"
                value={formData.pctOwnership}
                onChange={handleChange}
                className="themed-input cursor-pointer"
                required
              >
                <option value="">Select Percentage</option>
                <option value="10">10%</option>
                <option value="20">20%</option>
                <option value="30">30%</option>
                <option value="40">40%</option>
                <option value="50">50%</option>
                <option value="60">60%</option>
                <option value="70">70%</option>
                <option value="80">80%</option>
                <option value="90">90%</option>
                <option value="100">100%</option>
              </select>
            </div>
          </div>

          <div className="mt-4 mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2">Does applicant have existing lender balances over $50,000?</label>
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="hasLargeLenderBalances"
                  value="yes"
                  checked={formData.hasLargeLenderBalances === 'yes'}
                  onChange={handleChange}
                  className='h-[50px] w-[20px] cursor-pointer'
                />
                <span className="text-[14px] font-semibold">Yes</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="hasLargeLenderBalances"
                  value="no"
                  checked={formData.hasLargeLenderBalances === 'no'}
                  onChange={handleChange}
                  className='h-[50px] w-[20px] cursor-pointer'
                />
                <span className="text-[14px] font-semibold">No</span>
              </label>
            </div>
          </div>
        
          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="bankStatement1">Bank Statement 1 *</label>
              <input
                style={fieldStyles.bankStatement1}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="bankStatement1"
                name="bankStatement1"
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="bankStatement2">Bank Statement 2 *</label>
              <input
                style={fieldStyles.bankStatement2}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="bankStatement2"
                name="bankStatement2"
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="bankStatement3">Bank Statement 3 *</label>
              <input
                style={fieldStyles.bankStatement3}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="bankStatement3"
                name="bankStatement3"
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="bankStatement4">Bank Statement 4</label>
              <input
                style={fieldStyles.bankStatement4}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="bankStatement4"
                name="bankStatement4"
                onChange={handleChange}
                className="themed-input"
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessApplication">Business Application *</label>
              <input
                style={fieldStyles.businessApplication}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="businessApplication"
                name="businessApplication"
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="plBusinessStatement">2023 P&L Business Statement</label>
              <input
                style={fieldStyles.plBusinessStatement}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="plBusinessStatement"
                name="plBusinessStatement"
                onChange={handleChange}
                className="themed-input"
              />
            </div>
          </div>


          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="taxReturn1">Business Tax Return 1</label>
              <input
                style={fieldStyles.taxReturn1}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="taxReturn1"
                name="taxReturn1"
                onChange={handleChange}
                className="themed-input"
              />
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="taxReturn2">Business Tax Return 2</label>
              <input
                style={fieldStyles.taxReturn2}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="taxReturn2"
                name="taxReturn2"
                onChange={handleChange}
                className="themed-input"
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="driversLicenseFront">Driver's License Front *</label>
              <input
                style={fieldStyles.driversLicenseFront}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="driversLicenseFront"
                name="driversLicenseFront"
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="personalTaxReturn1">Personal Tax Return 1</label>
              <input
                style={fieldStyles.personalTaxReturn1}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="personalTaxReturn1"
                name="personalTaxReturn1"
                onChange={handleChange}
                className="themed-input"
              />
            </div>
            {/* <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="driversLicenseBack">Driver's License Back</label>
              <input
                style={fieldStyles.driversLicenseBack}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="driversLicenseBack"
                name="driversLicenseBack"
                onChange={handleChange}
                className="themed-input"
              />
            </div> */}
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="microSbaApplication">MICRO SBA Application</label>
              <input
                style={fieldStyles.microSbaApplication}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="microSbaApplication"
                name="microSbaApplication"
                onChange={handleChange}
                className="themed-input"
              />
            </div>

            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="quidityLibriumSBA">Quidity Librium SBA</label>
              <input
                style={fieldStyles.quidityLibriumSBA}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="file"
                id="quidityLibriumSBA"
                name="quidityLibriumSBA"
                onChange={handleChange}
                className="themed-input"
              />
            </div>
          </div>

          <div className='mt-2 mb-2'>
            <input id="checkbox-create-deal" type="checkbox" className='mr-2 cursor-pointer scale-[1.2]' value={isCheckboxChecked} onChange={() => setIsCheckboxChecked(!isCheckboxChecked)} />
            <label className='cursor-pointer text-sm' htmlFor="checkbox-create-deal">
              The above aforementioned (“Referrer”) refers clients for Quidity, LLC's loan programs and certifies authority to submit applications. The Referrer confirms all information and referrals are accurate and truthful. Referrer authorizes Quidity, LLC, and affiliates to (a) Obtain reports about the merchant, (b) Access the merchant's financial statements, (c) Share information with authorized parties, (d) Receive information from banks/creditors, (e) Contact the merchant via text, call, or email. The referrer is responsible for information accuracy. This authorization lasts through the loan process and term unless revoked. Quidity, LLC will use the information solely for loan evaluation and servicing, complying with applicable laws.
            </label>
          </div>
          {
            !isCheckboxChecked &&
            <p className='mt-4 font-bold text-red-500'>
              Please confirm above information.
            </p>
          }
          {
            !isFormValid &&
            <p className='mt-4 font-bold text-red-500'>
              Please fill all required fields.
            </p>
          }
          <div className="flex items-center justify-between">
          <button
            type="submit"
            className="mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[50%] disabled:hover:bg- disabled:bg-gray-600"
            disabled={!isFormValid || loading}
          >
            {!loading ? "Create Deal" : <div className='flex items-center'><div className="button-loader mr-2"></div>Please wait</div>}
          </button>
          </div>
          <p className="mt-4 font-bold text-themeColorPrimary2">{loading && submitStatusMessage}</p>
        </form>
        <p className='mt-4 font-bold text-red-500'>
          {errorMessage}
        </p>
      </div>
    </div>
  );
};

export default CreateDealPage;
