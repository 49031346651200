import React, { useEffect, useState } from 'react'
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import moment from 'moment';
import { useLocation } from 'wouter';

const AdminPage = ({
  user,
  selectedDeal,
  setSelectedDeal,
  selectedAccount,
  setSelectedAccount,
  setDetailModalOpen,
  setAccountDetailsModalOpen,
}) => {
  const [deals, setDeals] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('deals'); // Tab state

  useEffect(() => {
    const fetchDeals = async () => {
      const dealsQuery = query(collection(db, "deals"), orderBy('dateSubmitted', 'asc'));
      const querySnapshot = await getDocs(dealsQuery);

      const dealsData = querySnapshot.docs.reverse().map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDeals(dealsData);
      setLoading(false);
    };

    const fetchAccounts = async () => {
      const accountsQuery = query(collection(db, "users"), orderBy('createdAt', 'asc'));
      const querySnapshot = await getDocs(accountsQuery);

      const accountsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAccounts(accountsData);
      setLoading(false);
    };

    fetchDeals();
    fetchAccounts();
  }, [user, selectedDeal, selectedAccount]);

  const handleViewDeal = (deal) => {
    setSelectedDeal(deal);
    setDetailModalOpen(true);
  };

  const handleViewAccount = (account) => {
    setSelectedAccount(account);
    setAccountDetailsModalOpen(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
      <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>

        {/* Tab Navigation */}
        <div className="flex justify-center mb-6">
          <button
            onClick={() => handleTabChange('deals')}
            className={`px-4 py-2 text-lg font-bold ${activeTab === 'deals' ? 'border-b-4 border-[#078586]' : ''}`}
          >
            Deals
          </button>
          <button
            onClick={() => handleTabChange('accounts')}
            className={`px-4 py-2 text-lg font-bold ml-4 ${activeTab === 'accounts' ? 'border-b-4 border-[#078586]' : ''}`}
          >
            Accounts
          </button>
        </div>

        {/* Deals Tab */}
        {activeTab === 'deals' && (
          <div>
            <h1 className="text-4xl font-bold my-4 mb-[30px]">All Deals</h1>
            {deals.length > 0 ? (
              <table className="w-full px-6">
                <thead>
                  <tr className="text-center text-xl">
                    <th className="pb-4">Date Submitted</th>
                    <th className="pb-4">ISO</th>
                    <th className="pb-4">ISO Referrer</th>
                    <th className="pb-4">Business Name</th>
                    <th className="pb-4">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {deals.map((deal) => (
                    <tr
                      key={deal.id}
                      className="mb-4 shadow-lg rounded-2xl border w-full cursor-pointer hover:shadow-xl hover:border-themeColorPrimary transition-all"
                    >
                      <td className="font-semibold text-base text-center mb-2 py-4">
                        {moment(deal.dateSubmitted.toDate()).format('MM - DD - YYYY')}
                      </td>
                      <td className="text-base text-center mb-2 py-4 cursor-pointer font-bold underline" onClick={() => handleViewDeal(deal)}>
                        {deal.companyName}
                      </td>
                      <td className="text-base text-center mb-2 py-4 cursor-pointer font-bold underline" onClick={() => handleViewDeal(deal)}>
                        {deal.referrer}
                      </td>
                      <td className="font-semibold text-base text-center mb-2 py-4">{deal.businessName}</td>
                      <td className="font-semibold text-base text-center mb-2 py-4">{deal.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              !loading && (
                <div>
                  <table className="w-full px-6">
                    <thead>
                      <tr className="text-center text-xl">
                        <th className="pb-4">Date Submitted</th>
                        <th className="pb-4">ISO</th>
                        <th className="pb-4">ISO Referrer</th>
                        <th className="pb-4">Business Name</th>
                        <th className="pb-4">Status</th>
                      </tr>
                    </thead>
                  </table>
                  <p className="w-full text-center mt-4 text-2xl font-bold text-red-600">No deals found!</p>
                </div>
              )
            )}
          </div>
        )}

        {/* Accounts Tab */}
        {activeTab === 'accounts' && (
          <div>
            <h1 className="text-4xl font-bold my-4 mb-[30px]">All Accounts</h1>
            {accounts.length > 0 ? (
              <table className="w-full px-6">
                <thead>
                  <tr className="text-center text-xl">
                    <th className="pb-4">Account Name</th>
                    <th className="pb-4">Email</th>
                    <th className="pb-4">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((account) => (
                    <tr key={account.id} className="mb-4 shadow-lg rounded-2xl border w-full">
                      <td className="font-semibold text-base text-center mb-2 py-4 underline cursor-pointer" onClick={() => handleViewAccount(account)}>{account.companyName}</td>
                      <td className="font-semibold text-base text-center mb-2 py-4">{account.userId}</td>
                      <td className="font-semibold text-base text-center mb-2 py-4">{moment(account.createdAt.toDate()).format('MM - DD - YYYY')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              !loading && (
                <p className="w-full text-center mt-4 text-2xl font-bold text-red-600">No accounts found!</p>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
