// src/routes.js
import React, { useState } from 'react';
import { Route, Switch } from 'wouter';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import CreateDeal from './pages/CreateDeal';
import PortalPage from './pages/PortalPage';
import AdminPage from './pages/AdminPage';
import DealDetailModal from './modals/DealDetailModal';
import ModifyDeals from './pages/ModifyDeal';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import AccountDetailModal from './modals/AccountDetailModal';

const Routes = ({ user, userInfo, setUserInfo }) => {
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [isAccountDetailsModalOpen, setAccountDetailsModalOpen] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const closeDealModal = () => {
    setSelectedDeal(null);
    setDetailModalOpen(false);
  };

  const closeAccountDetailsModal = () => {
    setSelectedAccount(null)
    setAccountDetailsModalOpen(false);
  };

  return (
    <div>
      <Switch>
        <Route path="/" component={LoginPage} />
        <Route path="/register" component={RegistrationPage} />
        <Route path="/create-account" component={RegistrationPage} />
        <Route path="/terms">
          <TermsAndConditionsPage user={user} userInfo={userInfo} setUserInfo={setUserInfo}/>
        </Route>
        <Route path="/create-deal">
          <CreateDeal user={user} userInfo={userInfo} />
        </Route>
        <Route path="/portal">
          <PortalPage user={user} userInfo={userInfo} setSelectedDeal={setSelectedDeal} setDetailModalOpen={setDetailModalOpen} />
        </Route>
        <Route path="/deal/modify/:id">
          <ModifyDeals user={user} userInfo={userInfo} />
        </Route>
        <Route path="/admin">
          <AdminPage
            user={user}
            userInfo={userInfo}
            selectedDeal={selectedDeal}
            setSelectedDeal={setSelectedDeal}
            setSelectedAccount={setSelectedAccount}
            setDetailModalOpen={setDetailModalOpen}
            setAccountDetailsModalOpen={setAccountDetailsModalOpen}
          />
        </Route>
      </Switch>

      {
        isDetailModalOpen && selectedDeal && (
          <DealDetailModal user={user} userInfo={userInfo} selectedDeal={selectedDeal} onClose={closeDealModal} />
        )
      }
      {
        isAccountDetailsModalOpen !== null && (
          <AccountDetailModal user={user} userInfo={userInfo} selectedAccount={selectedAccount} onClose={closeAccountDetailsModal} />
        )
      }
    </div>
  );
}

export default Routes;
