import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { FiDownload } from 'react-icons/fi';
import { fetchUserInfo, sendStatusChangeEmail } from '../utils/helpers';

const DealDetailModal = ({ user, selectedDeal, onClose }) => {

  // const formatDate = (date) => moment(date).format('MMMM Do, YYYY');

  const [dealUser, setDealUser] = useState(false)

  const [loading, setLoading] = useState(false)

   // Assuming you have a function to update the status in your backend or state
   const [dealStatus, setDealStatus] = useState(selectedDeal.status);

   useEffect(() => {
    fetchUserInfo(user.uid)
    .then((userObject) => {
      setDealUser(userObject)
    })

   }, [selectedDeal])

   const getStatusDescription = (status) => {
    const descriptions = {
        "AWAITING STATEMENTS": "We're waiting to receive your financial statements. Please submit them as soon as possible.",
        "CONTRACT COMPLETED": "Your contract has been completed. We'll be in touch with next steps.",
        "DECLINED": "We regret to inform you that your application has been declined. [Reason for decline, if applicable]",
        "DECLINED FOR CREDIT": "We regret to inform you that your application has been declined due to credit reasons.",
        "DECLINED FOR INDUSTRY": "We regret to inform you that your application has been declined due to industry reasons.",
        "DECLINED FOR LOW BALANCE": "We regret to inform you that your application has been declined due to low balance.",
        "DECLINED FOR RECENT FUNDING": "We regret to inform you that your application has been declined due to recent funding.",
        "DECLINED FOR VOLUME": "We regret to inform you that your application has been declined due to volume reasons.",
        "DOC REQUEST SENT": "We've sent a request for additional documentation. Please check your email and provide the requested documents as soon as possible.",
        "IN CLOSING": "Your application is in the final stages. We'll be in touch shortly to complete the process.",
        "IN INITIAL REVIEW": "Your application is in the initial review stage.",
        "OFFER READY": "We have an offer ready for you. Please check your email for details.",
        "OFFER SENT": "We've sent you an offer. Please review it and let us know if you have any questions.",
        "PLAID RESENT": "We've resent a Plaid connection request. Please complete this step to continue your application.",
        "PLAID SENT": "We've sent a Plaid connection request. Please complete this step to continue your application.",
        "PRE APPROVED UNDER OFFICIAL REVIEW": "Your application has been pre-approved and is now undergoing official review.",
        "QUESTION FOR BROKER": "We have a question for your broker. They will be in touch shortly.",
        "SUBMITTED": "We've received your application and will begin processing it shortly.",
        "UNDER FINANCIAL REVIEW": "Our team is currently reviewing your financial information.",
        "WITHDRAWN": "Your application has been withdrawn as per your request."
    };

    return descriptions[status] || "Status not found.";
};


   const handleStatusChange = (event) => {
     const newStatus = event.target.value;
     setDealStatus(newStatus);
   };

   const handleStatusUpdate = async () => {
    setLoading(true)
      try {
        await setDoc(doc(db, 'deals', selectedDeal.id), { status: dealStatus }, { merge: true })
        await sendStatusChangeEmail(selectedDeal.referrer, selectedDeal.referrerEmail, dealStatus, getStatusDescription(dealStatus), selectedDeal.businessName)
        setLoading(false)
        onClose()
      } catch (error) {
        setLoading(false)
        console.error("Error updating status: ", error);
      }
   }

  return (
    <>
      <Modal onClose={onClose}>
				<div className="mt-3 text-left">
        <h2 className="text-xl font-semibold mb-4">{selectedDeal.businessName}</h2>

        <section className="mb-4">
          <h2 className="text-lg underline font-bold mb-2">Business Information:</h2>
          <p><b>Referrer Name:</b> {selectedDeal.referrer}</p>
          <p><b>Referrer Email:</b> {selectedDeal.referrerEmail}</p>
          {/* <p><b>DBA:</b> {selectedDeal.dba}</p> */}
          <p><b>Industry:</b> {selectedDeal.industry}</p>
          <p><b>Description:</b> {selectedDeal.businessDescription}</p>
          <p><b>Address:</b> {selectedDeal.address}, {selectedDeal.city}, {selectedDeal.state} {selectedDeal.zip}</p>
          <p><b>Incorporated on:</b> {selectedDeal.dateIncorporated}</p>
          <p><b>{'MCA>$50k'}:</b> {selectedDeal.hasLargeLenderBalances}</p>
          <p><b>Use of funds:</b> {selectedDeal.useOfFunds || 'N/A'}</p>
        </section>

        <section className="mb-4">
          <h3 className="text-lg underline font-bold mb-2">Owner Information</h3>
          <p><b>Owner:</b> {selectedDeal.ownerName}</p>
          <p><b>Email:</b> {selectedDeal.ownerEmail}</p>
          <p><b>Date of Birth:</b> {selectedDeal.ownerDOB}</p>
          <p><b>SSN: </b>{selectedDeal.ownerSSN}</p>
          <p><b>Telephone:</b> {selectedDeal.ownerTel}</p>
          <p><b>Ownership:</b> {selectedDeal.pctOwnership}%</p>
        </section>

        <section className="mb-4">
          <h3 className="text-lg underline font-bold mb-2">Documents</h3>
          <ul>
            <li>
              <p className='mb-2'>
                {
                  selectedDeal.bankStatement1 && selectedDeal.bankStatement1 !== 'N/A'
                  ?
                  <p>Click on each document to download:</p>
                  :
                  <p>
                    Documents are being uploaded.
                    <br />
                    Please check back in a few minutes.
                  </p>
                }
              </p>
            </li>
            {
              selectedDeal.bankStatement1 && selectedDeal.bankStatement1 !== 'N/A' &&
              <li>
                <a href={selectedDeal.bankStatement1} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Bank Statement 1
                </a>
              </li>
            }
            {
              selectedDeal.bankStatement2 && selectedDeal.bankStatement2 !== 'N/A' &&
              <li>
                <a href={selectedDeal.bankStatement2} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Bank Statement 2
                </a>
              </li>
            }
            {
              selectedDeal.bankStatement3 && selectedDeal.bankStatement3 !== 'N/A' &&
              <li>
                <a href={selectedDeal.bankStatement3} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Bank Statement 3
                </a>
              </li>
            }
            {
              selectedDeal.bankStatement4 && selectedDeal.bankStatement4 !== 'N/A' &&
              <li>
                <a href={selectedDeal.bankStatement4} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Bank Statement 4
                </a>
              </li>
            }
            {
              selectedDeal.businessApplication && selectedDeal.businessApplication !== 'N/A' &&
              <li>
                <a href={selectedDeal.businessApplication} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Business Application
                </a>
              </li>
            }
            {
              selectedDeal.driversLicenseFront && selectedDeal.driversLicenseFront !== 'N/A' &&
              <li>
                <a href={selectedDeal.driversLicenseFront} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Driver's License Front
                </a>
              </li>
            }
            {
              selectedDeal.driversLicenseBack && selectedDeal.driversLicenseBack !== 'N/A' &&
              <li>
                <a href={selectedDeal.driversLicenseBack} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Driver's License Back
                </a>
              </li>
            }
            {
              selectedDeal.voidedCheck && selectedDeal.voidedCheck !== 'N/A' &&
              <li>
                <a href={selectedDeal.voidedCheck} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Voided Check
                </a>
              </li>
            }
            {
              selectedDeal.taxReturn1 && selectedDeal.taxReturn1 !== 'N/A' &&
              <li>
                <a href={selectedDeal.taxReturn1} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Business Tax Return 1
                </a>
              </li>
            }
            {
              selectedDeal.taxReturn2 && selectedDeal.taxReturn2 !== 'N/A' &&
              <li>
                <a href={selectedDeal.taxReturn2} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Business Tax Return 2
                </a>
              </li>
            }
            {
              selectedDeal.personalTaxReturn1 && selectedDeal.personalTaxReturn1 !== 'N/A' &&
              <li>
                <a href={selectedDeal.personalTaxReturn1} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Personal Tax Return 1
                </a>
              </li>
            }
            {
              selectedDeal.microSbaApplication && selectedDeal.microSbaApplication !== 'N/A' &&
              <li>
                <a href={selectedDeal.microSbaApplication} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />MICRO SBA Application
                </a>
              </li>
            }
            {
              selectedDeal.quidityLibriumSBA && selectedDeal.quidityLibriumSBA !== 'N/A' &&
              <li>
                <a href={selectedDeal.quidityLibriumSBA} download className='flex items-center font-semibold'>
                  <FiDownload className="mr-2" />Quidity Librium SBA
                </a>
              </li>
            }
          </ul>
        </section>

        <section>
          <p><b>Agreed to Terms:</b> {dealUser ? 'Yes' : 'No'}</p>
          <br />
          <p>
            <b className='mr-2'>Status:</b>
            <select value={dealStatus} onChange={handleStatusChange} className="mt-1 cursor-pointer themed-input" disabled={!user?.email?.startsWith('admin')}>
              <option value="AWAITING STATEMENTS">AWAITING STATEMENTS</option>
              <option value="CONTRACT COMPLETED">CONTRACT COMPLETED</option>
              <option value="DECLINED">DECLINED</option>
              <option value="DECLINED FOR CREDIT">DECLINED FOR CREDIT</option>
              <option value="DECLINED FOR INDUSTRY">DECLINED FOR INDUSTRY</option>
              <option value="DECLINED FOR LOW BALANCE">DECLINED FOR LOW BALANCE</option>
              <option value="DECLINED FOR RECENT FUNDING">DECLINED FOR RECENT FUNDING</option>
              <option value="DECLINED FOR VOLUME">DECLINED FOR VOLUME</option>
              <option value="DOC REQUEST SENT">DOC REQUEST SENT</option>
              <option value="IN CLOSING">IN CLOSING</option>
              <option value="IN INITIAL REVIEW">IN INITIAL REVIEW</option>
              <option value="OFFER READY">OFFER READY</option>
              <option value="OFFER SENT">OFFER SENT</option>
              <option value="PLAID RESENT">PLAID RESENT</option>
              <option value="PLAID SENT">PLAID SENT</option>
              <option value="PRE APPROVED UNDER OFFICIAL REVIEW">PRE-APPROVED UNDER OFFICIAL REVIEW</option>
              <option value="QUESTION FOR BROKER">QUESTION FOR BROKER</option>
              <option value="SUBMITTED">SUBMITTED</option>
              <option value="UNDER FINANCIAL REVIEW">UNDER FINANCIAL REVIEW</option>
              <option value="WITHDRAWN">WITHDRAWN</option>
            </select>
          </p>
          {user?.email?.startsWith('admin') &&
            <div className="mt-6 flex justify-center">
                <button className='px-4 py-2 bg-themeColorPrimary text-white font-bold rounded cursor-pointer disabled:opacity-90 disabled:cursor-not-allowed' onClick={handleStatusUpdate} disabled={loading}>{loading ? <div className='flex gap-2 items-center'>Updating Status <div className="button-loader" /></div> : "Update Status"}</button>
            </div>
          }
          </section>

          {
            !user?.email?.startsWith('admin')
            &&
            selectedDeal.bankStatement1 && selectedDeal.bankStatement1 !== 'N/A' &&
            <div className="mt-6 flex justify-center">
              <a className='px-4 py-2 bg-themeColorPrimary text-white font-bold rounded' href={`/deal/modify/${selectedDeal.id}`} dis>Modify</a>
            </div>
          }
        </div>
      </Modal>
    </>
  );
};

export default DealDetailModal;
